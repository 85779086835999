import {
  isRequired,
  isRequiredCheckbox,
  isRequiredCheckbox1,
  isRequiredSelect,
  isValidBranchName,
  isValidDescription,
  isValidEmail1,
  isValidNumberField,
  isValidOpeningHourse,
  isValidPhoneNumber,
  isValidPostCode,
  multiOptionRequiredCheckbox,
} from '../../../vendor/pages/Validation/Validation';
import { handleErrorResponse, handleSuccessResponse, vendorOrgnizationEdit } from '../../../services';
export const handleChangeValue = (
  e,
  setFormValue,
  reactTrix,
  formValue,
  setErr,
  setApplyDisable
) => {
  const errorLogs = validation({
    ...formValue, // Pass existing form values
    [e.target.name]: e.target.value, // Update the current field being changed
  });
  setErr && setErr(errorLogs);
  if (reactTrix) {
    const name = e.target.name;
    let value = e.target.data;
    setFormValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  } else {
    const { name, value, checked } = e.target;
    const errorLogs = validation({
      ...formValue, // Pass existing form values
      [name]: value, // Update the current field being changed
    });
    setErr && setErr(errorLogs);
    if (name === 'postcode') {
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else {
      if (
        name === 'cash_payment' ||
        name === 'pay_online' ||
        name === 'serviceMyLocation' ||
        name === 'serviceCustomerLocation'
      ) {
        setFormValue((prevValues) => ({
          ...prevValues,
          [name]: checked,
        }));
      } else if (name === 'city') {
        setFormValue((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      } else if (name === 'countrycode' || name === 'ccountrycode') {
        let countryCode = value.split(' ');
        let countryCode1 = countryCode[0].toString();
        setFormValue((prevValues) => ({
          ...prevValues,
          [name]: countryCode1,
        }));
      } else {
        setApplyDisable && setApplyDisable(false);
        let tempArray = name.split('.');
        if (tempArray && tempArray.length >= 2) {
          if (
            tempArray[1].toString() === 'open' ||
            tempArray[1].toString() === 'close'
          ) {
            const [, day, property] = name.match(/\[([^\]]+)\]\.([^\s.]+)/);
            setFormValue((prevState) => ({
              ...prevState,
              bussiness_hours: {
                ...prevState.bussiness_hours,
                [day.toLowerCase()]: {
                  ...(prevState.bussiness_hours &&
                    prevState.bussiness_hours[day.toLowerCase()]),
                  [property]: value,
                },
              },
            }));
          } else if (tempArray[1].toString() === 'shop_open') {
            const day = name.match(/\[([^\]]+)\]/)[1].toLowerCase();
            setFormValue((prevState) => ({
              ...prevState,
              bussiness_hours: {
                ...prevState.bussiness_hours,
                [day]: {
                  ...(prevState.bussiness_hours &&
                    prevState.bussiness_hours[day]),
                  shop_open: checked,
                },
              },
            }));
          }
        } else {
          setFormValue((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
      }
    }
  }
};
const removeUnwantedTagsEng = (content) => {
  if (!content) return ''; // Return an empty string if content is null or undefined
  const pattern =
    /<h\d+>\s*(null|&nbsp;)?\s*<\/h\d+>|<>\s*(null|&nbsp;)?\s*<\/>/gi;
  content = content.replace(pattern, '');
  return content;
};

const removeUnwantedTagsThai = (content) => {
  if (!content) return ''; // Return an empty string if content is null or undefined
  const pattern =
    /<h\d+>\s*(null|&nbsp;)?\s*<\/h\d+>|<>\s*(null|&nbsp;)?\s*<\/>/gi;
  content = content.replace(pattern, '');
  return content;
};

export const handleSubmit = async (
  e,
  formValue,
  setErr,
  formType,
  navigateUrl,
  navigate,
  closeButtonObject,
  setButtonDisabled,
  t,
) => {
  let err = {};
  e.preventDefault();
  err = validation(formValue, formType, t);
  setErr(err);
  let response;
  formValue.venueEnglishDescription = removeUnwantedTagsEng(
    formValue.venueEnglishDescription,
  );
  formValue.venueThaiDescription = removeUnwantedTagsThai(
    formValue.venueThaiDescription,
  );
  if (
    (Object.keys(err).length === 2 &&
      err.venueEnglishDescription === '' &&
      err.venueThaiDescription === '') ||
    Object.keys(err).length === 0
  ) {
    try {
      setButtonDisabled(true);
      const formData = appendData(formValue);
      response = await vendorOrgnizationEdit(formData);
      handleSuccessResponse(response, response.data.message);
      setButtonDisabled(false);
    } catch (error) {
      handleErrorResponse(error);
      setButtonDisabled(false);
    }
  } else {
    setButtonDisabled(false);
  }
};

const appendData = (formValue) => {
  const formData = new FormData();
  formData.append('city', formValue.city);
  formData.append('website', formValue?.website);
  formData.append('postcode', formValue.postcode);
  let removeCountryNameCountryCode = formValue.countrycode
    ? formValue.countrycode.split('(').length > 0 &&
    formValue.countrycode.split('(')[0].toString()
    : '+66';
  formData.append(
    'phone',
    removeCountryNameCountryCode + ' ' + formValue.venuPhoneNumber,
  );
  formData.append('location', formValue.address);
  formData.append('email', formValue.email);
  if (formValue.venue_name) formData.append('name', formValue.venue_name);
  formData.append('name_thai', formValue.venueThaiName);
  formData.append('description_thai', formValue.venueThaiDescription);
  formData.append('description', formValue.venueEnglishDescription);
  formData.append(
    'service_at_bussiness_location',
    !formValue.serviceMyLocation || formValue.serviceMyLocation === 'null'
      ? ''
      : formValue.serviceMyLocation,
  );
  formData.append(
    'service_at_customer_location',
    !formValue.serviceCustomerLocation ||
      formValue.serviceCustomerLocation === 'null'
      ? ''
      : formValue.serviceCustomerLocation,
  );
  formData.append('break_time', formValue.breakTime);
  formData.append(
    'cash_payment',
    formValue.cash_payment === undefined || !formValue.cash_payment
      ? false
      : formValue.cash_payment,
  );
  formData.append(
    'rescheduling_permitted_days',
    !formValue.cancelDayBeforeScheduleDate ||
      formValue.cancelDayBeforeScheduleDate === 'null'
      ? ''
      : formValue.cancelDayBeforeScheduleDate,
  );
  formData.append(
    'online_payment',
    formValue.pay_online === undefined || !formValue.pay_online
      ? false
      : formValue.pay_online,
  );
  formData.append(
    'cancel_days_before_scheduled',
    !formValue.dayValidAfterPurchase ||
      formValue.dayValidAfterPurchase === 'null'
      ? ''
      : formValue.dayValidAfterPurchase,
  );
  if (formValue.bussiness_hours) {
    Object.keys(
      Object.keys(formValue.bussiness_hours) && formValue.bussiness_hours,
    ).forEach((day) => {
      const dayData = formValue.bussiness_hours[day];
      formData.append(`bussiness_hours[${day}][open]`, dayData.open);
      formData.append(`bussiness_hours[${day}][close]`, dayData.close);
      formData.append(`bussiness_hours[${day}][shop_open]`, dayData.shop_open);
    });
  }
  if (formValue.shopImagesShow && formValue.shopImagesShow.length > 0) {
    formValue.shopImagesShow.forEach((image) => {
      formData.append('images[]', image);
    });
  }
  if (!formValue.venue_name || formValue.venue_name === 'null')
    formData.append('is_freelancer', true);

  return formData;
};

export const validation = (formValue, formType, t) => {
  let checkErr = {};
  if (formType === 'deletetionRequest') {
    checkErr = isRequiredCheckbox1(
      formValue.deletetionRequestCheckbox1,
      { ...checkErr },
      'deletetionRequestCheckbox1',
      t,
    );
    checkErr = isRequiredCheckbox1(
      formValue.deletetionRequestCheckbox2,
      { ...checkErr },
      'deletetionRequestCheckbox2',
      t,
    );
  }
  if (formType === 'openingHourse') {
    checkErr = multiOptionRequiredCheckbox(
      formValue.serviceMyLocation,
      formValue.serviceCustomerLocation,
      { ...checkErr },
      'serviceLocation',
      `${t('validation.serviceLocationOption')}`,
      t,
    );

    checkErr = multiOptionRequiredCheckbox(
      formValue.pay_online,
      formValue.cash_payment,
      { ...checkErr },
      'paymentOption',
      `${t('validation.paymentOption')}`,
      t,
    );
    checkErr = isRequiredCheckbox(
      formValue.breakTime,
      { ...checkErr },
      'breakTime',
      `${t('validation.breakTime')}`,
      t,
    );
    checkErr = isValidOpeningHourse(
      formValue.bussiness_hours,
      { ...checkErr },
      t,
    );
    checkErr = isValidNumberField(
      formValue.dayValidAfterPurchase,
      checkErr,
      'dayValidAfterPurchase',
      t,
    );
    checkErr = isValidNumberField(
      formValue.cancelDayBeforeScheduleDate,
      checkErr,
      'cancelDayBeforeScheduleDate',
      t,
    );
  }
  if (formType === 'accountEdit') {
    checkErr = isValidPhoneNumber(
      formValue.venuPhoneNumber,
      { ...checkErr },
      'venuPhoneNumber',
      `${t('field.phoneNumber')}`,
      '',
      t,
    );
    checkErr = isRequired(
      formValue.address,
      { ...checkErr },
      'address',
      `${t('field.address')}`,
      t,
    );
    checkErr = isValidEmail1(formValue.email, { ...checkErr }, 'email', t);
    checkErr = isValidDescription(
      formValue.venueEnglishDescription,
      { ...checkErr },
      'venueEnglishDescription',
      `${t('validation.en')}`,
      t,
    );
    checkErr = isValidDescription(
      formValue.venueThaiDescription,
      { ...checkErr },
      'venueThaiDescription',
      `${t('validation.thai')}`,
      t,
    );
    checkErr = isValidPostCode(formValue.postcode, { ...checkErr });
    checkErr = isRequiredSelect(
      formValue.city,
      { ...checkErr },
      'city',
      `${t('field.city')}`,
      t,
    );
  }
  if (formType === 'isEditBranchProfile') {
    checkErr = isValidBranchName(
      formValue.branchName,
      { ...checkErr },
      'branchName',
      `${t('field.branchName')}`,
      t,
    );
    checkErr = isRequired(
      formValue.address,
      { ...checkErr },
      'address',
      `${t('field.address')}`,
      t,
    );
    checkErr = isRequiredSelect(
      formValue.subDistrict,
      { ...checkErr },
      'subDistrict',
      `${t('field.subDistrict')}`,
      t,
    );
    checkErr = isRequiredSelect(
      formValue.postalCode,
      { ...checkErr },
      'postcode',
      `${t('field.postalCode')}`,
      t,
    );
    checkErr = isRequiredSelect(
      formValue.city,
      { ...checkErr },
      'city',
      `${t('field.city')}`,
      t,
    );
  }

  return checkErr;
};

export const handleApplyAll = (
  e,
  setApplyAll,
  openingHourseFormValue,
  setOpeningHourseFormValue,
  previousBusinessHours,
  setPreviousBusinessHours
) => {
  const isChecked = e.target.checked;
  setApplyAll(isChecked);

  // Define all days of the week
  const allDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  if (isChecked) {
    setPreviousBusinessHours(openingHourseFormValue?.bussiness_hours);

    const mondayHours = openingHourseFormValue?.bussiness_hours?.monday;
    const updatedHours = allDays.reduce((acc, day) => {
      acc[day] = {
        open: mondayHours?.open, // Default opening time if not set
        close: mondayHours?.close, // Default closing time if not set
        shop_open: mondayHours?.shop_open !== undefined ? mondayHours?.shop_open : true, // Default shop_open to true
      };
      return acc;
    }, {});

    setOpeningHourseFormValue({
      ...openingHourseFormValue,
      bussiness_hours: updatedHours,
    });
  } else {
    setOpeningHourseFormValue({
      ...openingHourseFormValue,
      bussiness_hours: previousBusinessHours,
    });
  }
};
